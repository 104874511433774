/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Typography, makeStyles } from '@material-ui/core';
import Layout from '../components/layout';
import Hero from '../components/Hero/Hero';
import SEO from '../components/seo';
import PageControls from '../components/PageControls/PageControls';
import BlogPostCard from '../components/BlogPostCard/BlogPostCard';
import { DEFAULT_BLOG_RESULTS_PER_PAGE } from '../utils/constants';

const useStyles = makeStyles((theme) => ({
  blog: {
    margin: '0px 20px',
    [theme.breakpoints.up('lg')]: {
      margin: '0px 200px',
    },
  },
  header: {
    paddingTop: '2.4rem',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 10,
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const Blog = () => {
  const classes = useStyles();
  const [pointer, setPointer] = useState(0);
  const blogsPointerKey = 'blogsPointer';
  useEffect(() => {
    sessionStorage.setItem(blogsPointerKey, '0');
  }, []);

  const data = useStaticQuery(graphql`
    query blogImageQueries {
      allWordpressWpMedia(
        filter: { source_url: { regex: "/blogs-page-banner/" } }
      ) {
        edges {
          node {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 10000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      allWordpressWpBlogs(sort: { fields: date, order: DESC }) {
        nodes {
          slug
          title
          id
          date
          acf {
            author
            excerpt
            hero_image {
              source_url
            }
          }
        }
      }
    }
  `);
  const {
    allWordpressWpBlogs: { nodes: posts },
  } = data;
  const {
    fluid,
  } = data.allWordpressWpMedia.edges[0].node.localFile.childImageSharp;

  return (
    <Layout>
      <SEO title="Blogs" />
      <Hero fluid={fluid} />
      <div className={classes.blog}>
        <Typography
          variant="h1"
          className={classes.header}
          id="pageHeading"
          data-testid="pageHeading"
        >
          Welcome to our Blog Page
        </Typography>
        <Typography>
          Read the blog posts below from organizations listed on the site to
          find out more about them as well as cancer care related articles in
          the news. If you would like to write a piece for inclusion, please
          contact us at hello@cancercaremap.org
        </Typography>
        {posts
          .slice(pointer, pointer + DEFAULT_BLOG_RESULTS_PER_PAGE)
          .map(
            ({
              title,
              slug,
              id,
              date,
              acf: { author, excerpt, hero_image: heroImage },
            }) => {
              const sourceUrl = heroImage
                ? heroImage.source_url
                : 'https://via.placeholder.com/200';
              return (
                <BlogPostCard
                  key={id}
                  title={title}
                  slug={slug}
                  author={author}
                  date={date}
                  excerpt={excerpt}
                  sourceUrl={sourceUrl}
                />
              );
            },
          )}
        <hr />
        <div className={classes.pagination}>
          {posts.length > 1 && (
            <PageControls
              id="pageControls"
              data-testid="pageControls"
              pointer={pointer}
              resultsPerPage={DEFAULT_BLOG_RESULTS_PER_PAGE}
              setPointer={setPointer}
              totalResults={posts.length}
              storageName={blogsPointerKey}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
