import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { makeStyles } from '@material-ui/core/';

const useStyles = makeStyles(({ breakpoints }) => ({
  banner: {
    width: '100%',
    objectFit: 'cover',
    maxHeight: '375px',
    paddingBottom: '2.4rem',
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const Hero = ({ fluid, src }) => {
  const { banner } = useStyles();
  return fluid ? (
    <Img fluid={fluid} alt="hero" className={banner} />
  ) : (
    <img src={src} alt="hero" className={banner} />
  );
};

Hero.propTypes = {
  src: PropTypes.string,
  fluid: PropTypes.shape({
    base64: PropTypes.string,
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
  }),
};

Hero.defaultProps = {
  src: '',
  fluid: null,
};

export default Hero;
