import React from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import { Grid, Typography, Box, makeStyles } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(
  ({ breakpoints: { up }, palette: { coreRed } }) => ({
    link: {
      border: `1px solid ${coreRed}`,
      borderRadius: '40px',
      color: coreRed,
      padding: '5px 33px',
      fontSize: '16px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textDecoration: 'none',
    },
    image: {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '188px',
      minHeight: '15rem',
      width: '100%',
      [up('md')]: {
        minHeight: 'auto',
      },
    },
    btnWrapper: {
      textAlign: 'center',
      [up('sm')]: {
        paddingTop: '40px',
        textAlign: 'left',
      },
    },
    postTitle: {
      fontSize: '1.5rem',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    date: {
      fontSize: '0.875rem',
      fontWeight: 'normal',
      margin: 0,
    },
  }),
);

const BlogPostCard = ({ title, slug, author, date, excerpt, sourceUrl }) => {
  const classes = useStyles();
  const path = `/article/${slug}`;

  const navigateToProvider = () => {
    navigate(`/article/${slug}`);
  };
  return (
    <Box className={classes.article} component="article" py={7}>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={4}>
          <div
            style={{ backgroundImage: `url(${sourceUrl})` }}
            className={classes.image}
            data-testid="post-image"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Typography variant="h4" className={classes.date}>
            {date
              ? `${moment(date, 'YYYY-MM-DDTHH:mm:ss. sssZ').format(
                  'DD MMMM YYYY',
                )} — `
              : ''}
            {author}
          </Typography>
          <Typography
            variant="h3"
            className={classes.postTitle}
            onClick={() => navigateToProvider()}
          >
            {title}
          </Typography>
          <Typography gutterBottom>{excerpt}</Typography>
          <Box className={classes.btnWrapper} pt={3}>
            <Link to={path} className={classes.link}>
              Read post
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

BlogPostCard.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  sourceUrl: PropTypes.string.isRequired,
};

export default BlogPostCard;
